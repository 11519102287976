import { useCallback, useEffect, useRef, useState } from 'react';

import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import * as Yup from 'yup';

import { ImageLogo } from '~/assets/icons';
import { Input, InputMask, Button, Select } from '~/components';
import { useAuth } from '~/hooks/Auth';
import { useLoading } from '~/hooks/Loading';
import { useProfessor } from '~/hooks/professor';
import { useStudent } from '~/hooks/Student';
import { useToast } from '~/hooks/Toast';
import {
  RegisterProfessionalRequestForm,
  SelectOptionProps,
  SubmitHandler,
} from '~/models/Common';
import { UserComplement, ViaCepAddress } from '~/models/User';
import UserService from '~/services/UserService';
import errorHandlerToToast from '~/utils/errorHandler';
import { getMinorMaxDate, formatDate } from '~/utils/getTodayDate';
import getValidationErrors from '~/utils/getValidationErrors';
import { getCEP } from '~/utils/handleCEP';
import { mockStates } from '~/utils/mocks/mockStates';
import { useQuery } from '~/utils/query';
import { cast } from '~/utils/yupHelpers';

import { Container, Label } from './styles';

interface routeParams {
  publicname: string;
}

interface StatePlan {
  plan: {
    planId: number;
    planAmount: number;
    planName: string;
    numberVideos: number;
  };
}

const SignupPaymentData: React.FC = () => {
  const courseId = useQuery().get('course');
  const isPlan = useQuery().get('plan');
  const liveId = useQuery().get('live');
  const isProfessional = useQuery().get('professional');
  const state = useLocation().state as StatePlan;
  console.log(state);

  const { signin, user, updateUserAndBack } = useAuth();
  const { addToast } = useToast();
  const { setLoading } = useLoading();
  const { student } = useStudent();
  const { professor } = useProfessor();

  const formRef = useRef<FormHandles>(null);
  const { publicname } = useParams() as routeParams;
  const history = useHistory();

  const [initialData, setInitialData] = useState<Partial<UserComplement>>();
  const [lastCEP, setLastCEP] = useState<string>();
  const [selectedAddressState, setSelectedAddressState] = useState('');
  const addressStateOptions = useRef<SelectOptionProps[]>(
    mockStates?.map(k => ({ label: k.sigla, value: k.sigla })),
  );
  const [address, setAddress] = useState<ViaCepAddress>({
    address_city: '',
    address_complement: '',
    address_neighborhood: '',
    address_state: '',
    address_street: '',
  });

  useEffect(() => {
    if (user?.address_state) setSelectedAddressState(user?.address_state);

    user?.cell_phone
      ? setInitialData({
          cell_phone: user.cell_phone || '',
          date_of_birth:
            (user.date_of_birth && formatDate(user.date_of_birth, 'invert')) ||
            '',
          address_zip_code: user.address_zip_code || '',
          address_street: user.address_street || '',
          address_number: user.address_number || '',
          address_complement: user.address_complement || '',
          address_neighborhood: user.address_neighborhood || '',
          address_state: user.address_state || '',
          address_city: user.address_city || '',
        })
      : setInitialData({
          ...address,
        });
  }, [address, user]);

  if (!student && courseId) {
    history.push(`/${publicname}/signup?course=${courseId}`);
  }

  if (
    ((!student && isPlan === 'true') || !student?.name) &&
    isProfessional !== 'true'
  ) {
    history.push(`/${publicname}/signup?plan=true`);
  }

  const handleSubmitPayments: SubmitHandler<RegisterProfessionalRequestForm> = useCallback(
    async ({ cpf, ...rest }) => {
      setLoading(true);
      const paymentsData = {
        ...rest,
      };
      try {
        if (paymentsData.date_of_birth) {
          paymentsData.date_of_birth = formatDate(paymentsData.date_of_birth);
          const isValid =
            new Date(paymentsData.date_of_birth).toString() !== 'Invalid Date';
          if (!isValid) {
            formRef.current?.setFieldError('date_of_birth', 'Data inválida');
            throw new Error('Data inválida');
          }
        } else {
          formRef.current?.setFieldError('date_of_birth', 'Data é obrigatória');
          throw new Error('Data inválida');
        }
        student.address_state = selectedAddressState;
        user.address_state = selectedAddressState;

        formRef.current?.setErrors({});
        const schema = Yup.object().shape({
          cell_phone: Yup.string(),
          date_of_birth: Yup.date()
            .min(getMinorMaxDate(120, 'min'), 'Idade inválida')
            .max(getMinorMaxDate(18, 'min'), 'Mínimo 18 anos'),
          address_zip_code: Yup.number()
            .integer()
            .positive()
            .validCEP('É preciso um CEP válido')
            .transform(cast),
          address_street: Yup.string(),
          address_number: Yup.number().integer().positive().transform(cast),
          address_complement: Yup.string(),
          address_neighborhood: Yup.string(),
          address_state: Yup.string(),
          address_city: Yup.string(),
        });

        await schema.validate(paymentsData, {
          abortEarly: false,
        });

        const studentData = {
          ...student,
          ...paymentsData,
        };

        const professorData = { ...user, ...paymentsData };
        console.log(professorData);
        // comentar para não chamar a api
        if (isProfessional) {
          await UserService.update({
            userId: user.role === 'professional' ? user?.professional?.id : 1,
            data: professorData,
            type: 'professional',
          });
        } else {
          await UserService.update({
            userId: user.role === 'student' ? user.student.id : 1,
            data: studentData,
            type: 'student',
          });
        }

        updateUserAndBack({ user: paymentsData, redirect: false });

        setLoading(false);
        addToast({
          type: 'success',
          title: 'Dados de pagamento atualizado com sucesso',
          description: 'Prossiga com o pagamento',
        });

        const { email, password } = student;

        if (password) {
          signin({ email, password });
        }
        if (isProfessional) {
          history.push(
            `/${publicname}/payment?professional=${isProfessional}`,
            state,
          );
        }

        if (courseId) {
          history.push(`/${publicname}/payment?course=${courseId}`);
        }
        if (isPlan === 'true') {
          history.push(`/${publicname}/payment?plan=true`);
        }
        if (liveId) {
          history.push(`/${publicname}/payment?live=${liveId}`);
        }
      } catch (error: any) {
        setLoading(false);

        const responseErr = errorHandlerToToast({
          error,
          formRef,
          description: 'Cheque se as credenciais foram inseridas corretamente.',
        });
        if (responseErr) addToast(responseErr);

        const errors = getValidationErrors(error);

        Object.values(errors).forEach((value: string) => {
          addToast({
            title: 'Verifique os dados inseridos',
            description: value,
            type: 'error',
          });
        });
      }
    },
    [
      isProfessional,
      setLoading,
      student,
      user,
      updateUserAndBack,
      addToast,
      courseId,
      isPlan,
      liveId,
      signin,
      history,
      publicname,
      selectedAddressState,
    ],
  );

  const handleCEP = useCallback(
    async ({
      target: { value: cepWithMark },
    }: React.ChangeEvent<HTMLInputElement>) => {
      try {
        const addressViaCep = await getCEP(cepWithMark, lastCEP);
        setLastCEP(cepWithMark);
        setAddress(addressViaCep);
      } catch (error: any) {
        const errorToToast = errorHandlerToToast({ error });
        if (errorToToast) addToast(errorToToast);
      }
    },

    [addToast, lastCEP],
  );

  return (
    <Container>
      <img src={ImageLogo} alt="" />

      <Form
        ref={formRef}
        onSubmit={handleSubmitPayments}
        initialData={{ ...initialData }}
      >
        <InputMask
          name="cell_phone"
          type="tel"
          placeholder="Telefone"
          mask="(99) 99999-9999"
        />

        <Label>Data de nascimento:</Label>
        <InputMask
          name="date_of_birth"
          type="tel"
          placeholder="dd/mm/aaaa"
          mask="99/99/9999"
        />

        <InputMask
          placeholder="CEP"
          name="address_zip_code"
          mask="99999-999"
          type="tel"
          onBlur={handleCEP}
        />

        <Input name="address_street" placeholder="Endereço" />

        <Input name="address_neighborhood" placeholder="Bairro" />

        <Input
          name="address_number"
          type="number"
          accessibilityText="Número"
          placeholder="Nº:"
        />

        <Input name="address_complement" placeholder="Complemento" />

        <Input name="address_city" placeholder="Cidade" />

        <Select
          name="address_state"
          options={addressStateOptions.current}
          value={selectedAddressState}
          setValue={setSelectedAddressState}
        />

        <Button type="submit" isOutline={false}>
          Continuar para o pagamento
        </Button>
      </Form>
    </Container>
  );
};

export default SignupPaymentData;
