import styled from 'styled-components';

import { colors } from '~/styles/settings/colors';

export const PriceBase = styled.span`
  font-size: '1.2rem';
  color: 'gray';
  font-weight: 'normal';
  align-self: 'flex-start';
  margin-top: '8px';
`;

export const ModalContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  padding: 15px;

  p {
    font-size: 12px;
    margin-top: 5px;
    margin-bottom: 20px;
    width: 100%;
    color: gray;
  }

  h1 {
    color: ${colors.lightnerGrey};
    font-size: 28px;
    font-weight: bold;
    letter-spacing: 1.12px;
  }

  h6 {
    margin-top: 15px;
    color: ${colors.lightnerGrey} !important;
    font-size: 18px;
  }

  form {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    div {
      display: flex;
      max-width: 373px;
      width: 100%;
      margin-top: 25px;
      border-color: #000;
      color: #fff;

      input {
        color: ${colors.lightGrey};
        width: 100%;
        max-width: 373px;

        &::placeholder {
          color: ${colors.lightGrey};
        }
      }
    }
  }
`;

export const CreateCourse = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 463px;
  width: 100%;

  > button {
    margin-top: 42px;
    background: #2c2c2c 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #57575729;
    border-radius: 4px;
    width: 100%;
    max-width: 100%;
  }

  form {
    justify-content: center;
    align-items: center;
    margin-top: 20px;

    h6 {
      font-size: 19px;
      font-weight: 600;
      color: #2c2c2c;
      width: 100%;
      margin-bottom: 10px;
    }

    > div input {
      color: #2c2c2c;
      width: 100%;
      max-width: 373px;
    }

    > div {
      max-width: 100%;
      width: 100%;
      margin: 0;

      div {
        color: #2c2c2c;
        border-color: #2c2c2c;
        padding-right: 20px;
        width: 20px;

        svg: {
          width: 20px;
        }

        span {
          margin-left: 8px;
        }
      }
    }
  }

  button {
    &:last-child {
      margin-top: 20px;
    }
  }
`;

export const Camera = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  max-width: 393px;
  width: 100%;
  height: 240px;
  background: ${colors.gray} 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #57575729;
  border-radius: 4px;

  input {
    visibility: hidden;
  }

  label {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    width: 100%;
    height: 100%;
    overflow: hidden;

    img {
      height: 100%;
      width: auto;
    }
  }

  img {
    width: 96px;
    height: 79px;
  }

  span {
    color: #ffffff;
    font-size: 21px;
    font-weight: 600;
    text-align: center;
    margin-top: 15px;
  }
`;

export const ImagePreviewContainer = styled.div`
  width: 100%;
  height: 100%;
  background-color: red;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;

  img {
    flex: 1;
    width: auto;
    height: 100%;
  }
`;
