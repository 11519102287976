import styled from 'styled-components';

import { colors } from '~/styles/settings/colors';
import { breakpoints } from '~/styles/tools/breakpoints';

export const ListGroup = styled.div`
  flex: 1;
  display: flex;
  width: 100%;
  flex-direction: column;
  gap: 1rem;
  overflow: hidden;
`;

export const ListHeader = styled.div`
  flex: 1;
  display: flex;
  justify-content: space-between;
`;

export const ListTitle = styled.h3`
  color: #f4f4f4;
`;

export const ListLink = styled.a`
  color: #f4f4f4;
  text-decoration: underline;
`;

export const ListIcon = styled.div`
  position: absolute;
  height: 100%;
  width: 50px;
  z-index: 999;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;

  color: #f4f4f4;
  font-weight: bold;
  font-size: 4rem;

  background: rgba(0, 0, 0, 0.5);

  @media (max-width: 600px) {
    display: none;
  }
`;

export const ListBody = styled.div`
  flex: 1;
  gap: 2rem;
  width: 100%;
  display: flex;
  overflow-x: hidden;
  position: relative;
`;

export const ListContent = styled.div`
  display: flex;
  width: 100%;
  border: 2px solid red;

  @media (max-width: 600px) {
    overflow-x: auto;
  }
`;

export const SlideWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  max-width: 25%;

  @media (max-width: ${breakpoints.small}) {
    max-width: 70%;
  }
`;

export const Title = styled.h5`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  color: #9b9062;
  font-size: 1.75rem;
`;

export const Category = styled.p`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  color: #b1b1b1;
  font-size: 1.2rem;
`;

export const Subtitle = styled.p`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  color: ${colors.lightGrey};
  padding: 4px;
`;

export const LivePrice = styled.p`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  color: #fff;
  font-weight: 600;
  font-size: 2rem;
`;

export const ListLeftIcon = styled(ListIcon)`
  left: 0;

  ::before {
    content: '<';
  }
`;
export const ListRightIcon = styled(ListIcon)`
  right: 0;

  ::before {
    content: '>';
  }
`;

export const ListCard = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  transform: scale(0.9);
  cursor: pointer;
  transition: all ease 0.2s;

  :hover {
    transform: scale(1);
  }
`;

export const CardImage = styled.img`
  width: 320px;
  height: 180px;
  object-fit: cover;

  @media (max-width: 600px) {
    width: 140px;
  }
`;
export const CardCategory = styled.span`
  color: #f4f4f4;

  @media (max-width: 600px) {
    display: none;
  }
`;
export const CardTitle = styled.h4`
  color: #f4f4f4;

  @media (max-width: 600px) {
    display: none;
  }
`;

export const InteractionContentButton = styled.a`
  width: 100%;
  padding: 0;
  display: flex;
  justify-content: center;

  > button {
    background-color: #2c2c2c;
    width: 100%;
    max-width: 100%;
    height: 44px;
    text-transform: none;
    padding: 0;
  }
`;
