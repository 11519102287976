import { ButtonHTMLAttributes } from 'react';

import { Container } from './styles';

interface ButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  isOutline?: boolean;
  active?: boolean;
}

const Button: React.FC<ButtonProps> = ({
  children,
  isOutline,
  active,
  ...rest
}) => {
  return (
    <Container isOutline={isOutline} active={active} {...rest}>
      {children}
    </Container>
  );
};

export default Button;
