import { useState, useEffect, useCallback, useRef } from 'react';

import { FaTrash } from 'react-icons/fa';
import { MdEdit } from 'react-icons/md';

import Courses from '~/models/Courses';
import Interaction from '~/models/Interaction';
import { Lives } from '~/models/Lives';
import Practice from '~/models/Practice';
import Video from '~/models/Video';
import { Workouts } from '~/models/Workouts';
import NewInteraction from '~/pages/Professor/Courses/CourseDetail/NewInteraction';
import NewPractice from '~/pages/Professor/Courses/CourseDetail/NewPractice';
import NewVideo from '~/pages/Professor/Courses/CourseDetail/NewVideo';
import NewCourse from '~/pages/Professor/Courses/MyCourses/NewCourse';
import NewWorkout from '~/pages/Professor/MyWorkouts/NewWorkout';

import { Dropdown, List, Item } from './styles';

interface DropdownProps {
  dropdownIsOpen: boolean;
  setDropdownIsOpen?: (state: boolean) => void;
  id: string;
  dropdownSelected?: string;
  live?: Lives;
  workout?: Workouts;
  course?: Courses;
  video?: Video;
  practice?: Practice;
  interaction?: Interaction;
  handleDelete?: () => void;
  setNeedUpdate?: (arg: boolean) => void;
  getWorkouts?: () => Promise<void>;
  getLives?: () => Promise<void>;
  isModalOpen?: boolean;
  setIsModalOpen?: (arg: boolean) => void;
}

const DropdownMenu: React.FC<DropdownProps> = ({
  dropdownIsOpen,
  setDropdownIsOpen,
  handleDelete,
  id,
  dropdownSelected,
  workout,
  course,
  video,
  live,
  practice,
  interaction,
  setNeedUpdate,
  isModalOpen,
  setIsModalOpen,
}) => {
  const isSelected = id === dropdownSelected;
  const dropdownContainer = useRef<HTMLDivElement>(null);
  const [modalEditIsOpen, setModalEditIsOpen] = useState(false);

  useEffect(() => {
    const pageClickEvent = (e: MouseEvent): void => {
      if (
        !dropdownContainer.current?.contains(e.target as Node) &&
        setDropdownIsOpen
      ) {
        setDropdownIsOpen(false);
      }
    };

    if (dropdownIsOpen) {
      window.addEventListener('click', pageClickEvent);
    }

    return () => {
      window.removeEventListener('click', pageClickEvent);
    };
  }, [dropdownContainer, dropdownIsOpen, setDropdownIsOpen, setIsModalOpen]);

  useEffect(() => {
    setIsModalOpen && setIsModalOpen(modalEditIsOpen);
  }, [modalEditIsOpen, setIsModalOpen]);

  const handleOpenModalEdit = useCallback(() => {
    setModalEditIsOpen(!modalEditIsOpen);
    setIsModalOpen && setIsModalOpen(!modalEditIsOpen);
    console.log('live', live )
  }, [modalEditIsOpen, setIsModalOpen, setModalEditIsOpen]);

  return (
    <>
      <div id={id}>
        {dropdownIsOpen && (
          <Dropdown ref={dropdownContainer} isSelected={isSelected}>
            <List>
              <Item
                onClick={e => {
                  e.stopPropagation();
                  handleOpenModalEdit();
                }}
              >
                <MdEdit size={20} />
                Editar
              </Item>

              <Item
                onClick={e => {
                  e.stopPropagation();
                  handleDelete && handleDelete();
                }}
              >
                <FaTrash size={20} />
                Excluir
              </Item>
            </List>
          </Dropdown>
        )}
        {(workout || live) && (
          <NewWorkout
            modalIsOpen={modalEditIsOpen}
            setModalIsOpen={setModalEditIsOpen}
            workout={workout}
            live={live}
          />
        )}
        {course && !video && !practice && (
          <NewCourse
            modalIsOpen={modalEditIsOpen}
            setModalIsOpen={setModalEditIsOpen}
            course={course}
            setNeedUpdate={() => setNeedUpdate && setNeedUpdate(true)}
          />
        )}
        {video && (
          <NewVideo
            modalIsOpen={modalEditIsOpen}
            setModalIsOpen={setModalEditIsOpen}
            video={video}
            courseId={course?.id ?? 1}
            setNeedUpdate={() => setNeedUpdate && setNeedUpdate(true)}
          />
        )}
        {practice && (
          <NewPractice
            modalIsOpen={modalEditIsOpen}
            setModalIsOpen={setModalEditIsOpen}
            practice={practice}
            courseId={course?.id ?? 1}
            setNeedUpdate={() => setNeedUpdate && setNeedUpdate(true)}
          />
        )}
        {interaction && (
          <NewInteraction
            courseId={course?.id ?? 1}
            modalIsOpen={modalEditIsOpen}
            setModalIsOpen={setModalEditIsOpen}
            interaction={interaction}
          />
        )}
      </div>
    </>
  );
};

export default DropdownMenu;
