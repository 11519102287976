import { useCallback, useEffect, useState } from 'react';

import { GrFormCheckmark } from 'react-icons/gr';
import { Link, Redirect, useParams } from 'react-router-dom';
import SwiperCore, {
  Autoplay,
  Lazy,
  Navigation,
  Pagination,
  Scrollbar,
} from 'swiper';
import 'swiper/swiper-bundle.css';

import { ImageLogo } from '~/assets/icons';
import { Button } from '~/components';
import { useProfessor } from '~/hooks/professor';
import { useToast } from '~/hooks/Toast';
import { PageOptionsResponse } from '~/models/Common';
import Courses from '~/models/Courses';
import { cleanRequests } from '~/services/api';
import ProfessorService from '~/services/ProfessorService';
import errorHandlerToToast from '~/utils/errorHandler';
import Price from '~/utils/Price';

import { Container, Item, List, PlanBox } from './styles';

SwiperCore.use([Pagination, Navigation, Scrollbar, Autoplay, Lazy]);

interface routeParams {
  publicname: string;
}

const BecomeAStudent: React.FC = () => {
  const [, setCourses] = useState<PageOptionsResponse<Courses>>();
  const { publicname } = useParams() as routeParams;
  const { professor, addProfessor } = useProfessor();
  const [currency, setCurrency] = useState<string>();
  const { addToast } = useToast();

  const getProfessional = useCallback(async (): Promise<void> => {
    try {
      const response = await ProfessorService.getProfessionalByPublicname(
        publicname,
        'becomeastudent',
      );
      addProfessor(response.data);
    } catch (err: any) {
      const errorToToast = errorHandlerToToast({ error: err });
      if (errorToToast) addToast(errorToToast);
    }
  }, [addProfessor, addToast, publicname]);

  useEffect(() => {
    if (professor && professor.amount_receivable) {
      const price = new Price({ price: professor.amount_receivable });
      const charge = price.getChargeInFloat();
      const chargeInDecimal = Price.decimalUSToBr(charge);
      setCurrency(chargeInDecimal);
    }
  }, [professor]);

  useEffect(() => {
    if (!professor) {
      getProfessional();
    }

    return cleanRequests;
  }, [getProfessional, professor]);

  useEffect(() => {
    const getCourses = async (public_name: string): Promise<void> => {
      try {
        const response = await ProfessorService.getCourses(public_name);
        setCourses(response.data);
      } catch (err: any) {
        const errorToToast = errorHandlerToToast({ error: err });
        if (errorToToast) addToast(errorToToast);
      }
    };
    if (professor?.public_name) {
      getCourses(professor.public_name);
    }

    return cleanRequests;
  }, [addToast, professor]);

  if (!professor) {
    return <Redirect to="/404" exact />;
  }

  return (
    <Container>
      <img src={ImageLogo} alt="Logo" />
      <h1>Torne-se Aluno</h1>
      <h6>Selecione seu plano de treinamento ou um dos cursos.</h6>
      <h3>Plano de Treinamento</h3>
      <PlanBox>
        <List>
          <Item>
            <GrFormCheckmark size={20} />
            Aulas gravadas do Básico ao Avançado + Aulas Bônus.
          </Item>
          <Item>
            <GrFormCheckmark size={20} />
            Acesso à Plataforma Digital de Aulas ao Vivo.
          </Item>
          <Item>
            <GrFormCheckmark size={20} />
            Acesso à Plataforma Digital de Módulos de Treinamento.
          </Item>
          <Item>
            <GrFormCheckmark size={20} />
            Acesso à Plataforma Digital de Cursos.
          </Item>
          <Item>
            <GrFormCheckmark size={20} />
            Suporte Técnico.
          </Item>
          <Item style={{ fontSize: 14, color: 'grey', maxWidth: 450 }}>
            * Aulas ao vivo, módulos e cursos poderão ser vendidos à parte,
            segundo critério do seu treinador.
          </Item>
        </List>
        <div>
          <h5>
            {professor.amount_receivable ? (
              <>
                {'R$ '}
                <strong>{currency}</strong>
                /mês
              </>
            ) : (
              'Gratuito'
            )}
          </h5>
          <Link to={`/${publicname}/signup?plan=true`}>
            <Button>Selecionar</Button>
          </Link>
        </div>
      </PlanBox>
    </Container>
  );
};

export default BecomeAStudent;
