import { useState, useEffect, useCallback } from 'react';
import { BiCheckSquare, BiCheckbox } from 'react-icons/bi';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { useStripe } from '@stripe/react-stripe-js';
import SwiperCore, {
  Pagination,
  Navigation,
  Scrollbar,
  Autoplay,
  Lazy,
} from 'swiper';
import 'swiper/swiper-bundle.css';

import { ImageLogo } from '~/assets/icons';
import { Button } from '~/components';
import { useToast } from '~/hooks/Toast';
import ProfessorService from '~/services/ProfessorService';

import { Container, PlanBox, List, Item } from './styles';
import { IProfessionalPlansAdhesion } from '~/models/ProfessionalPlansAdhesion';

SwiperCore.use([Pagination, Navigation, Scrollbar, Autoplay, Lazy]);

interface routeParams {
  publicname: string;
}

interface StatePlan {
  plan: {
    planId: number;
    planAmount: number;
    planName: string;
    numberVideos: number;
  };
}

const ChooseNewPlan: React.FC = () => {
  const state = useLocation().state as StatePlan;
  const [plans, setPlans] = useState<IProfessionalPlansAdhesion[]>([]);
  const [currentPlan, setcurrentPlan] = useState<IProfessionalPlansAdhesion>();
  const [currency, setCurrency] = useState<string>();
  const { addToast } = useToast();
  const { push } = useHistory();
  const stripe = useStripe();
  const professionalSignsPlan = useCallback(async () => {
    try {
      const response = await ProfessorService.professionalsPlanSign({
        planId: currentPlan?.id ?? 0,
      });

      if (response?.status === 201) {
        addToast({
          title: 'Profissional cadastrado no plano',
          description: `Profissional cadastrado no plano gratuito`,
          type: 'info',
        });
        push('/');
      }
      // ADD NO CODIGO STRIPE
      stripe?.redirectToCheckout({
        sessionId: response.data.sessionId,
      });
    } catch (e: any) {
      console.log(e);
    }
  }, [addToast, push, stripe, currentPlan]);

  const handlerGetProfessionalPlansAdhesion = async (): Promise<void> => {
    try {
      const response = await ProfessorService.getallProfessionalPlansAdhesion();
      setPlans(response.data);
      console.log(response.data);
    } catch (error) {
      console.log(error);
    }
  };
  console.log('passou no choose plan');
  useEffect(() => {
    handlerGetProfessionalPlansAdhesion();
  }, []);

  useEffect(() => {
    if (currentPlan && currentPlan.amount) {
      setCurrency(currentPlan.amount.toFixed(2).replace('.', ','));
    }
  }, [currentPlan]);

  return (
    <Container>
      <img src={ImageLogo} alt="Logo" />
      <h6>Selecione seu novo plano.</h6>
      <h3>Planos de adesão</h3>
      <PlanBox>
        <List>
          {plans?.map(plan => {
            return (
              <Item onClick={() => setcurrentPlan(plan)}>
                {plan === currentPlan ? (
                  <BiCheckSquare size={20} />
                ) : (
                  <BiCheckbox size={20} />
                )}
                Plano {plan.name} - Até {plan.number_videos} vídeos.
              </Item>
            );
          })}
        </List>

        <div>
          <h5>
            {currentPlan?.amount ? (
              <>
                {'R$ '}
                <strong>{currency}</strong>
                /mês
              </>
            ) : (
              'Gratuito'
            )}
          </h5>
          <Button type="button" onClick={professionalSignsPlan}>
            Selecionar
          </Button>
        </div>
      </PlanBox>
    </Container>
  );
};

export default ChooseNewPlan;
