import { IoMdClose } from 'react-icons/io';
import { Styles } from 'react-modal';
import styled from 'styled-components';

import { colors } from '~/styles/settings/colors';

export const ModalStyles: Styles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    background: '#FFF 0% 0% no-repeat padding-box',
    color: '#000000',
    borderRadius: '8px',
    maxWidth: '882px',
    width: '100%',
    maxHeight: '90%',
    border: 'none',
    animation: 'bouseIn 1s ease-in-out',
    boxShadow: '0px 8px 15px #3E3E3E6B',
    backgroundColor: `${colors.black}`,
  },
  overlay: {
    zIndex: 300,
    background: 'rgba(44, 44, 44, 0.7)',
  },
};

export const IconClose = styled(IoMdClose)`
  position: absolute;
  top: 0;
  right: 0;
  cursor: pointer;
  color: ${colors.lightnerGrey};
  margin: 5px;

  transition: 180ms ease-in;

  &:hover {
    background: rgba(0, 0, 0, 0.08);
    border-radius: 50%;
  }
`;

export const ModalContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  padding: 15px;

  h1 {
    color: ${colors.lightnerGrey};
    font-size: 28px;
    font-weight: 700;
    letter-spacing: 1.12px;
    text-transform: uppercase;
  }

  h2 {
    color: ${colors.lightGrey};
  }

  p {
    text-align: left;
    font-size: 14px;
    color: ${colors.lightGrey};
    margin-top: 25px;
  }

  form {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  @media (max-width: 768.9px) {
    /* padding: 0; */
    max-width: 80%;
    h1 {
      text-align: center;
    }
  }
  @media (max-width: 362.9px) {
    /* padding: 0; */
    max-width: 76%;
  }
`;
