import styled from 'styled-components';

import { breakpoints } from '~/styles/tools/breakpoints';

export const Container = styled.div`
  position: absolute;
  z-index: 100;
  right: 0;
  top: 0;
  margin: 30px;
  overflow: hidden;

  @media (max-width: ${breakpoints.small}) {
    top: 82px;
  }
`;
