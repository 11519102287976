import styled, { css } from 'styled-components';

import Tooltip from '~/components/Tooltip';

interface ContainerProps {
  isFocused?: boolean;
  isErrored?: boolean;
}

export const Container = styled.div<ContainerProps>`
  display: flex;
  align-items: center;

  background: transparent;
  width: 100%;
  padding-bottom: 18px;
  padding-left: 20px;
  margin-bottom: 25px;

  border: 0;
  border-bottom: 2px solid #fff;
  color: #fff;

  ${props =>
    props.isErrored &&
    css`
      border-color: #c53030;
    `}
  ${props =>
    props.isFocused &&
    css`
      border-color: #fdf5a6;
    `}


  textarea {
    background: transparent;
    width: 100%;
    border: transparent;
    color: #000;
    resize: vertical;

    &::placeholder {
      color: #fff;
      font-weight: bold;

      ${props =>
        props.isFocused &&
        css`
          color: #fdf5a6;
        `}
    }
  }
`;

export const Error = styled(Tooltip)`
  height: 20px;
  svg {
    margin-right: 0;
    margin-left: 15px;
  }
`;
