import { useState } from 'react';

import { useField } from '@unform/core';
import MaskedInput from 'react-text-mask';

import { Input as InputBase } from '~/components';
import { InputMaskProps, MaskType } from '~/models/Common';
import { convertMaskToInputMask } from '~/utils/inputMaskUtils';

const Input: React.FC<InputMaskProps> = ({ name, mask, ...rest }) => {
  const [myMask] = useState<MaskType>(() => {
    if (typeof mask === 'string') {
      const converted = convertMaskToInputMask(mask);
      return converted;
    }

    return mask;
  });

  const [isBlur, setIsBlur] = useState(false);
  const { defaultValue } = useField(name);

  const handleBlur = (): void => {
    setIsBlur(true);
  };

  const handleFocus = (): void => {
    setIsBlur(false);
  };

  return (
    <MaskedInput
      onBlur={handleBlur}
      mask={myMask}
      {...{ name, defaultValue }}
      {...rest}
      render={(ref, props) => (
        <InputBase
          parentBlur={isBlur}
          setParentFocus={handleFocus}
          {...{ name, ref }}
          {...props}
        />
      )}
    />
  );
};

export default Input;
