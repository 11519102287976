import { ChangeEvent, useCallback, useEffect, useState } from 'react';

import { writeText } from 'clipboard-polyfill/text';
import { serialize } from 'object-to-formdata';
import { MdCheck, MdContentCopy, MdShare } from 'react-icons/md';
import { useHistory } from 'react-router-dom';

import { ImageCamera } from '~/assets/icons';
import { ButtonBlack, Configuration, Input, Select } from '~/components';
import CurrencyInput from '~/components/CurrencyInput';
import { useAuth } from '~/hooks/Auth';
import { useToast } from '~/hooks/Toast';
import {
  ButtonBlackColor,
  Logo,
  PageCustomizeFormData,
  PageCustomizeRequest,
} from '~/models/Common';
import {
  Plan,
  PlanLabel,
  PlanTitle,
} from '~/pages/Student/Configuration/styles';
import ProfessorService from '~/services/ProfessorService';
import { ComplementaryText } from '~/styles/objects/complementary-text';
import { formatDate } from '~/utils/getTodayDate';
import Price from '~/utils/Price';

import {
  BoxImage,
  ButtonGroup,
  CancelButton,
  InputGroup,
  InputGrouptwo,
  Label,
  MonthlyPayment,
  Personalize,
  PersonalizeWrapper,
  PublicNameWrapper,
  Text,
  Title,
  UploadImageLabel,
} from './styles';
import { CouponServices } from '~/services/CouponServices';
import { User } from '~/models/UserContext';

// import { number } from 'yup/lib/locale';

type UpdatedLogo = Pick<Logo, 'name'> & Partial<Omit<Logo, 'name'>>;

interface PublicNameData {
  url: string;
  public_name: string;
}

interface PlanProfessionalAdhesions {
  id: number;
  created_at: string;
  updated_at: string;
  id_juno: any;
  name: string;
  amount: number;
  number_videos: number;
  price_id_stripe: string;
  product_id_stripe: string;
}

interface GetprofessionalSignedPlans {
  professionalSignedPlans: {
    professional_id: number;
    id_juno_sub: any;
    due_day: any;
    status: any;
    starts_on: any;
    last_billing_date: any;
    next_billing_date: any;
    id: number;
    created_at: string;
    updated_at: string;
    plan_professional_adhesions_id: number;
    id_stripe: string;
  };
  extractPaymentProfessionals: {
    id: number;
    description: string;
    amount: number;
    juno_id: any;
    status: string;
    created_at: string;
    updated_at: string;
    prof_signed_plans_id: number;
    id_stripe: string;
  };
  planProfessionalAdhesions: {
    id: number;
    created_at: string;
    updated_at: string;
    id_juno: any;
    name: string;
    amount: number;
    number_videos: number;
    price_id_stripe: string;
    product_id_stripe: string;
  };
}

const ConfigurationProfessor: React.FC = () => {
  const MAX_PRICE = 999.0;
  const { user } = useAuth();
  const [planData, setPlanData] = useState<GetprofessionalSignedPlans>();
  const { addToast } = useToast();
  const [isCopied, setCopied] = useState(false);
  const [uploadImage, setUploadImage] = useState<UpdatedLogo>();
  const [amountReceivable, setAmountReceivable] = useState<string>();
  const [amountCharge, setAmountCharge] = useState<string>();
  const { push } = useHistory();
  const [couponName, setCouponName] = useState<string>('');
  const [selectedDiscount, setSelectedDiscount] = useState<string>('');
  const [validate, setValidate] = useState<string>('');
  const [linkCoupon, setLinkCoupon] = useState<string>('');
  const [haveCoupon, setHaveCoupon] = useState<boolean>(false);
  const [couponActive, setCouponActive] = useState<boolean>(false);
  const [couponId, setCouponId] = useState<number>(0);

  const optionsValidity = [
    { value: '30', label: '30 dias' },
    { value: '60', label: '60 dias' },
    { value: '90', label: '90 dias' },
  ];

  const optionsDiscount = [
    { value: '10', label: '10%' },
    { value: '15', label: '15%' },
    { value: '20', label: '20%' },
    { value: '25', label: '25%' },
    { value: '30', label: '30%' },
  ];

  useEffect(() => {
    if (user.avatar) {
      setUploadImage({
        name: user.avatar,
      });
    }
  }, [user]);

  const handleCancelPlan = useCallback(
    async (professional_id: number) => {
      try {
        let check = false;
        check = window.confirm(
          'Seu plano atual vai ser cancelado e você sera direcionado para efetuar a assinatura de um novo plano. Tem certeza que deseja continuar ?',
        );
        if (check) {
          if (user.role === 'professional') {
            const response = await ProfessorService.cancelProfessionalSignedPlan(
              { professionalId: professional_id },
            );
            if (response.data.message === 'Plano cancelado')
              push('/professor/choose-new-plan');
          }
        }
      } catch (error) {
        console.log(error);
      }
    },
    [user],
  );

  const handleSetImage = useCallback(
    ({ target: { files } }: ChangeEvent<HTMLInputElement>) => {
      if (files) {
        const file = files[0];
        setUploadImage({
          name: URL.createObjectURL(file),
          file,
        });
      }
    },
    [],
  );

  const handleSubmit = useCallback(
    async (preData: PageCustomizeFormData): Promise<string> => {
      const data: PageCustomizeRequest = {
        ...preData,
        logo: uploadImage?.file,
      };

      const updateData = serialize<PageCustomizeRequest>(data);

      const {
        data: { avatar = '' },
      } = await ProfessorService.updateLogo({
        data: updateData,
      });

      addToast({
        title: 'Seu nome público ou foto foram alterados com sucesso!',
        type: 'success',
      });

      return avatar;
    },
    [addToast, uploadImage],
  );

  const getPublicName = useCallback((): PublicNameData => {
    if (user.role !== 'professional')
      throw new Error("This user can't copy/share");

    const { public_name } = user.professional;

    const { href: url } = new URL(public_name, process.env.REACT_APP_BASE_URL);

    return { url, public_name };
  }, [user]);

  const handleShare = useCallback(async (): Promise<void> => {
    try {
      const { url, public_name } = getPublicName();
      await navigator.share({
        title: 'Seu Coach',
        text: `Verifique a página do professor ${public_name}`,
        url,
      });
    } catch (error) {
      //
    }
  }, [getPublicName]);

  const handleCopy = useCallback(async (): Promise<void> => {
    if (!isCopied) {
      try {
        const { url } = getPublicName();
        await writeText(url);
        setCopied(true);
      } catch (error) {
        if (error instanceof Error) {
          addToast({ title: error.message, type: 'error' });
        }
      }
    }
  }, [addToast, getPublicName, isCopied]);

  const handleNewValue = (price: Price): void => {
    const chargeInCurrency = price.getChargeInCurrency();
    console.log('chargeInCurrency', chargeInCurrency);
    setAmountCharge(chargeInCurrency);
    setAmountReceivable(price.toCurrencyFormat());
  };

  const handleAmountReceivableChange = useCallback(
    (currency: string) => {
      const price = Price.fromCurrency({
        price: currency,
        basePrice: 0,
      });
      handleNewValue(price);
    },
    [amountReceivable, amountCharge],
  );

  const handleAmountChargeChange = useCallback(
    (charge: string): void => {
      const price = Price.fromCurrency({
        price: charge,
        basePrice: 0,
      });
      const priceAmountReceivable = price.getAmountReceivableInCurrency();
      setAmountCharge(price.toCurrencyFormat());
      setAmountReceivable(priceAmountReceivable);
      console.log('priceAmountReceivable', priceAmountReceivable);
    },
    [amountCharge, amountReceivable],
  );

  useEffect(() => {
    if (user?.role === 'professional') {
      const { amount_receivable } = user.professional;
      const price = new Price({
        price: amount_receivable,
        basePrice: 0,
      });
      handleNewValue(price);
    }
    const getPlanData = async (): Promise<void> => {
      try {
        let professional_id = 0;
        if (user.role === 'professional')
          professional_id = user.professional.id;
        const response = await ProfessorService.getprofessionalSignedPlans();
        setPlanData(response.data);
        console.log(response.data);
      } catch (error) {
        console.log(error);
      }
    };
    getPlanData();
  }, [user]);

  const getCoupon = async (): Promise<void> => {
    try {
      let professionalId;
      if (user.role === 'professional') {
        professionalId = user.professional.id;
      }
      const response = await CouponServices.getCoupon({ professionalId });
      const publicName = ((user as unknown) as User).professional.public_name;

      setCouponName(response.data.name);
      setSelectedDiscount(response.data.percent_off);
      setValidate(response.data.duration_days);
      setCouponActive(response.data.active);
      setCouponId(response.data.id);

      setHaveCoupon(true);

      setLinkCoupon(
        `Olá, tenho um cupom de desconto especial para você usar na minha plataforma, disponível para todos os produtos. Utilize o cupom: ${response.data.name} no meu site: ${window.location.origin}/${publicName}/payment?plan=true`,
      );
      return;
    } catch (error) {
      console.log(error);
    }
  };

  const handleCreateCoupon = async (): Promise<void> => {
    try {
      if (!couponName || !selectedDiscount || !validate) {
        addToast({
          title: 'Por favor, preencha todos os campos',
          type: 'error',
        });
        return;
      }

      const response = await CouponServices.CreateCoupon({
        name: couponName,
        percent_off: selectedDiscount,
        duration_days: validate,
      });

      console.log(response.data);
      const publicName = ((user as unknown) as User).professional.public_name;

      setHaveCoupon(true);
      setCouponActive(response.data.active);
      setCouponId(response.data.id);

      setLinkCoupon(
        `Olá, tenho um cupom de desconto especial para você usar na minha plataforma, disponível para todos os produtos. Utilize o cupom: ${response.data.name} no meu site: ${window.location.origin}/${publicName}/payment?plan=true`,
      );
    } catch (error: any) {
      console.log('Erro ao criar cupom:', error);
      addToast({
        title: 'Erro ao criar cupom. Tente novamente mais tarde.',
        type: 'error',
      });
    }
  };

  const handleStatusCoupon = async (): Promise<void> => {
    try {
      if (couponId && couponId === 0) {
        return;
      }

      const response = await CouponServices.toggleCouponStatus({ couponId });

      setCouponActive(response.data.active);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getCoupon();
  }, []);

  return (
    <Configuration optionalHandlerSubmit={handleSubmit}>
      <MonthlyPayment>
        <Title>Mensalidade por Cliente</Title>
        <InputGroup>
          <Label>
            Quanto você quer receber?
            <CurrencyInput
              name="professional.amount_receivable"
              callbackFunction={handleAmountReceivableChange}
              defaultValue={amountReceivable?.slice(3)}
              // min={Price.BASE_PRICE}
              // max={MAX_PRICE}
              text={amountReceivable?.slice(3)}
            />
            <span
              style={{
                fontSize: '12px',
                color: 'gray',
                fontWeight: 'normal',
              }}
            >
              Valor mínimo de {Price.floatToCurrency(Price.BASE_PRICE)} e máximo
              de {Price.floatToCurrency(MAX_PRICE)}.
            </span>
          </Label>
          <Label>
            Quanto será cobrado do seu aluno?
            {/* <InputMask
              name="original_price"
              maxLength={14}
              mask={currencyMask}
              value={amountCharge}
              onChange={(event) => handleAmountChargeChange(event.target.value)}
            /> */}
            <CurrencyInput
              name="original_price"
              callbackFunction={handleAmountChargeChange}
              defaultValue={amountCharge?.slice(3)}
              // min={22.78}
              // max={1130}
              text={amountCharge?.slice(3)}
            />
            <ComplementaryText style={{ marginTop: 0 }}>
              Acrescentados {Price.CHARGE_PERCENT - 4.5}% da plataforma + 4,5%
              de encargos financeiros + R$: 0.39 centavos de tarifas.
            </ComplementaryText>
          </Label>
        </InputGroup>
      </MonthlyPayment>

      <MonthlyPayment style={{ marginTop: '-100px' }}>
        <Title>Cupons de desconto</Title>
        <InputGrouptwo>
          <Label>
            Nome do cupom:
            <Input
              name="Nome do cuppom"
              value={couponName}
              placeholder="NomeDoSeuCupom"
              onChange={event => setCouponName(event.target.value)}
              maxLength={15}
            />
          </Label>

          <Label style={{ marginTop: '6px' }}>
            Validade:
            <Select
              name="validity"
              options={optionsValidity}
              value={validate}
              setValue={setValidate}
            />
          </Label>

          <Label style={{ marginTop: '6px' }}>
            Desconto:
            <Select
              name="discount"
              options={optionsDiscount}
              value={selectedDiscount}
              setValue={setSelectedDiscount}
            />
          </Label>

          <CancelButton
            style={{
              background: '#b88746 0% 0% no-repeat padding-box',
              color: 'white',
              height: '50px',
              width: '120px',
            }}
            onClick={() => handleCreateCoupon()}
            type="button"
          >
            Salvar cupom
          </CancelButton>
          {haveCoupon && (
            <>
              <Label>
                Compartilhar cupom:
                <Input
                  name="link"
                  icon
                  disabled
                  value="s.co/9ukjd1f0=1"
                  linkValue={linkCoupon}
                />
              </Label>

              <Text>
                {couponActive ? (
                  <span>
                    <strong>Status do cupom:</strong>
                    <p>Ativo</p>
                  </span>
                ) : (
                  <span>
                    <strong>Status do cupom:</strong>
                    <p style={{ backgroundColor: 'red' }}>Desativado</p>
                  </span>
                )}
              </Text>

              <CancelButton
                style={{
                  background: '#b88746 0% 0% no-repeat padding-box',
                  color: 'white',
                  height: '50px',
                  width: '200px',
                }}
                onClick={() => handleStatusCoupon()}
                type="button"
              >
                {couponActive ? 'Desativar cupom' : 'Ativar cupom'}
              </CancelButton>
            </>
          )}
        </InputGrouptwo>
      </MonthlyPayment>

      {planData && (
        <Plan>
          <PlanTitle>Plano atual</PlanTitle>
          <PlanLabel>
            <span>
              <strong>Nome:</strong>
              {planData.planProfessionalAdhesions.name}
            </span>
            <span>
              <strong>Valor: </strong>
              {planData.planProfessionalAdhesions.amount}
            </span>
            <span>
              <strong>Número de videos:</strong>
              {planData.planProfessionalAdhesions.number_videos}
            </span>
            {planData.professionalSignedPlans.next_billing_date && (
              <span>
                <strong>Proxima data de vancimento:</strong>
                {formatDate(
                  planData.professionalSignedPlans.next_billing_date,
                  'invert',
                )}
              </span>
            )}
            <CancelButton
              type="button"
              onClick={() => {
                handleCancelPlan(
                  planData.professionalSignedPlans.professional_id,
                );
              }}
            >
              {' '}
              Mudar plano
            </CancelButton>
          </PlanLabel>
        </Plan>
      )}
      <Personalize>
        <Title>Personalizar</Title>
        <PersonalizeWrapper>
          <BoxImage htmlFor="image">
            {!uploadImage && (
              <>
                <img src={ImageCamera} alt="Camera" />
                <span>Logo ou foto</span>
              </>
            )}
            {uploadImage && (
              <>
                <img
                  src={uploadImage.name}
                  alt="img"
                  style={{
                    width: 'auto',
                    height: '100%',
                    zIndex: 1,
                  }}
                />
                <UploadImageLabel>
                  <img
                    src={ImageCamera}
                    alt="Ícone Camera"
                    style={{ width: 'auto', height: 65 }}
                  />
                  <p>Editar foto</p>
                </UploadImageLabel>
              </>
            )}
            <input
              type="file"
              accept="image/jpeg,image/png"
              id="image"
              name="avatar"
              onChange={handleSetImage}
              hidden
            />
          </BoxImage>
          <PublicNameWrapper>
            <Label>
              Nome público:
              <Input name="professional.nick_name" />
            </Label>
            <ButtonGroup>
              <ButtonBlack
                stateUpload={
                  isCopied ? ButtonBlackColor.SUCESS : ButtonBlackColor.NORMAL
                }
                type="button"
                isLabelFile={false}
                onClick={handleCopy}
              >
                {isCopied ? <MdCheck /> : <MdContentCopy />}
              </ButtonBlack>
              {'share' in navigator && (
                <ButtonBlack
                  isLabelFile={false}
                  type="button"
                  onClick={handleShare}
                >
                  <MdShare />
                </ButtonBlack>
              )}
            </ButtonGroup>
          </PublicNameWrapper>
        </PersonalizeWrapper>
      </Personalize>
    </Configuration>
  );
};

export default ConfigurationProfessor;
